body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1,
h2 {
  font-family: "Oswald", sans-serif; }

h1 {
  text-transform: uppercase; }

h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif; }

.btn {
  font-family: "Oswald", sans-serif; }

p,
input,
a,
label {
  font-family: "Open Sans", sans-serif; }

.grid-button-label {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1em; }
  @media screen and (min-width: 576px) {
    .grid-button-label {
      font-size: 1em; } }
  @media screen and (min-width: 768px) {
    .grid-button-label {
      font-size: 1.4em; } }

a.judge-external-link {
  text-decoration: underline;
  font-weight: 600;
  color: #ff8c00 !important; }
  a.judge-external-link:hover {
    color: #ffa333 !important; }

.App {
  font-family: "Open Sans", sans-serif; }

.bg {
  min-height: 100vh;
  background: url("/img/concrete_seamless.png"); }

.admin-login,
.judge-login {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: white;
  color: #333;
  background: url("/img/concrete_seamless.png"); }

.logo {
  height: auto;
  max-height: 250px;
  max-width: 85%; }

.nav {
  background: url("/img/concrete_seamless.png");
  color: #333; }
  .nav .logo {
    max-height: 150px; }

a {
  color: #333; }
  a:hover, a:visited, a:active {
    color: #1a1a1a; }
  a.selected {
    color: #f08400;
    text-decoration: underline; }

.link {
  color: #ff8c00; }

.text-link {
  color: #00e5ff; }
  .text-link:hover, .text-link:visited, .text-link:active {
    color: #00b7cc; }

.section-header {
  margin-bottom: 1em; }

.spliter {
  border-right: 5px #ff8c00 solid; }

.btn {
  border: none;
  background-color: #ff8c00;
  font-family: "Open Sans", sans-serif; }
  .btn:hover {
    background-color: #e67e00;
    color: white; }
  .btn:visited {
    background-color: #ff8c00;
    color: white; }
  .btn:active {
    background-color: #663800;
    color: white; }
  .btn.btn-accent2 {
    background-color: #00e5ff; }
  .btn.btn-fonce {
    background-color: #d98826;
    color: white; }
    .btn.btn-fonce:hover {
      background-color: #ff8c00; }
  .btn.btn-danger {
    background-color: #ae1b02;
    color: white; }
  .btn.btn-disabled {
    background-color: #636363;
    color: #b5b5b5;
    font-style: italic;
    cursor: not-allowed !important; }
  .btn.btn-success {
    background-color: #00b7cc;
    color: white; }
  .btn.btn-sort {
    border: none; }
    .btn.btn-sort.sort-active:hover, .btn.btn-sort.sort-active:active, .btn.btn-sort.sort-active {
      border: 4px solid #d5ff80;
      background-color: #e6ffb3;
      color: #334d00; }
    .btn.btn-sort.sort-up::after {
      font-family: "Font Awesome 5 Free";
      content: "\F0D8";
      display: inline-block;
      padding-left: 10px;
      vertical-align: middle;
      font-weight: 900; }
    .btn.btn-sort.sort-down::after {
      font-family: "Font Awesome 5 Free";
      content: "   \F0D7";
      display: inline-block;
      padding-left: 10px;
      vertical-align: middle;
      font-weight: 900; }

.icon-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer; }
  .icon-button:hover {
    color: #ff8c00; }

.final-row {
  padding: 0.2em;
  border-radius: 5px; }
  .final-row:hover {
    background-color: #e6ffb3; }

.projectsListItem {
  background-color: #ffedd6;
  border: 1px white solid;
  font-size: 0.75em;
  padding: 1em 0.2em; }
  .projectsListItem:nth-of-type(odd) {
    background-color: #ffd8a8; }
  .projectsListItem:hover {
    background-color: #ccfaff; }
  .projectsListItem .more-infos {
    font-size: 1.75em; }
  .projectsListItem.missing-judge {
    box-shadow: 0 0 3px #ff0000;
    border: red 3px solid;
    background-color: #ffd3d3; }

.missing-judge {
  border: red 3px solid;
  background-color: #ffd3d3; }

.grid-cell-header {
  text-align: center; }

.grid-cell {
  text-align: center;
  border-right: 1px solid #ff8c00;
  font-weight: bolder; }
  .grid-cell:first-of-type {
    border-left: 1px solid #cc7000; }
  .grid-cell:last-of-type {
    border-right: none; }
  .grid-cell.grid-cell-complete {
    background-color: #ccff66; }
  .grid-cell.grid-cell-partial {
    background-color: #bfbfbf; }
  .grid-cell:hover {
    background-color: #66efff;
    cursor: pointer; }

.judge-finals-options {
  -webkit-appearance: none;
  /*Removes default chrome and safari style*/
  -moz-appearance: none;
  font-size: 17px;
  width: 50px;
  overflow: visible;
  overflow-wrap: break-word;
  -moz-white-space: pre-wrap;
  -o-white-space: pre-wrap;
  white-space: pre-wrap; }

.legend {
  font-size: 0.75em;
  font-weight: bolder;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }
  .legend .legend-block {
    padding: 0 0.4em;
    display: flex;
    flex-direction: column; }

.main-legend {
  font-size: 0.8em; }
  .main-legend .title {
    font-weight: bolder; }

.category {
  color: #ff8c00; }

.classification {
  color: #77b300; }

.type {
  color: #00a0b3; }

.judge-row {
  padding: 0.75em;
  border-bottom: 1px solid #ff8c00;
  font-size: 0.75em; }
  .judge-row:hover {
    background-color: #ccfaff; }
  .judge-row.is-invalid {
    border: 1px solid red;
    box-shadow: 1px 1px 1px 1px #c10000;
    background-color: #fff0f0; }
  .judge-row .judge-col {
    border-right: 1px solid #ff8c00; }
    .judge-row .judge-col .edit-mode {
      border-bottom: 2px solid #ff8c00; }
      .judge-row .judge-col .edit-mode .number-zone {
        font-size: 1.3em;
        font-weight: bolder;
        border: 2px solid #00e5ff; }
    .judge-row .judge-col .number-zone {
      font-size: 1.3em;
      font-weight: bolder;
      display: inline-block;
      min-width: 30px;
      min-height: 10px; }
    .judge-row .judge-col .edit-icon {
      border-radius: 10px;
      cursor: pointer; }
      .judge-row .judge-col .edit-icon.fa-edit {
        background-color: #ff8c00; }
      .judge-row .judge-col .edit-icon.fa-save {
        background-color: #00e5ff; }
  .judge-row .judge-pwd {
    text-align: center; }
    .judge-row .judge-pwd.pwd {
      -webkit-filter: blur(4px);
              filter: blur(4px); }

.spinner-container {
  /* Set up proportionate scaling */
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  z-index: 999;
  /* Set up positioning */
  top: 0;
  left: 0;
  background-color: #ff8c00; }

.judge-dashboard {
  min-height: 100vh;
  background-color: white;
  color: #333;
  background: url("/img/concrete_seamless.png"); }

.evaluation-section {
  color: #88cc00; }

.evaluation-subsection {
  color: black;
  border-radius: 0.4em;
  border: 2px solid #ff8c00;
  margin-bottom: 0.5em; }
  .evaluation-subsection:nth-of-type(even) {
    background-color: #ffba66; }
  .evaluation-subsection:nth-of-type(odd) {
    background-color: #ffddb3; }
  .evaluation-subsection.isComplete {
    border: 2px solid #00e5ff; }

.criterion-notes {
  line-height: 0.3em !important; }

.criterion-check {
  color: #008999; }

.grid-button-container {
  display: flex;
  flex-wrap: wrap; }

.grid-button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0; }

.grid-button-label {
  width: 2.3em;
  height: 2.3em;
  background-color: white;
  border: 2px solid #ff8c00;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.1em 0.1em; }
  @media screen and (min-width: 576px) {
    .grid-button-label {
      margin: 0.2em 0.2em;
      width: 3em;
      height: 3em; } }
  @media screen and (min-width: 768px) {
    .grid-button-label {
      margin: 0.1em 0.3em;
      width: 1.8em;
      height: 1.8em; } }
  .grid-button-label.active {
    background-color: #00e5ff;
    border: 2px solid #00b7cc;
    color: black; }

.grid-button {
  display: none; }

.period-row {
  border-radius: 0.4em; }
  .period-row:nth-of-type(even) {
    background-color: #ffba66; }
    .period-row:nth-of-type(even).is-complete {
      border: 4px solid #bbff33;
      background-color: #ccff66; }
  .period-row:nth-of-type(odd) {
    background-color: #ffddb3; }
    .period-row:nth-of-type(odd).is-complete {
      border: 4px solid #d5ff80;
      background-color: #e6ffb3; }

.ranking-row {
  border-radius: 0.4em; }
  .ranking-row:nth-of-type(even) {
    background-color: #ffba66; }
  .ranking-row:nth-of-type(odd) {
    background-color: #ffddb3; }
  .ranking-row:hover {
    background-color: #ccfaff; }
  .ranking-row.exequo {
    box-shadow: 0 0 1px #ff0000;
    border: red 2px solid;
    background-color: #ffd3d3; }
    .ranking-row.exequo .fas {
      cursor: pointer; }

.modal-xl {
  width: 90%;
  max-width: 1200px; }

.messageBox {
  position: absolute;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  border: 1px solid var(--main-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .messageBox .box {
    position: relative;
    width: 30%;
    min-height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }

.room-table tr:hover {
  background-color: #ff8c00; }

.room-table tbody th, .room-table tbody td {
  border: 1px solid black;
  min-width: 125px; }

