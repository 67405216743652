$font-title: "Oswald", sans-serif;
$font-text: "Open Sans", sans-serif;
h1,
h2 {
    font-family: $font-title;
}

h1 {
    text-transform: uppercase;
}

h3,
h4,
h5,
h6 {
    font-family: $font-text;
}

.btn {
    font-family: $font-title;
}

p,
input,
a,
label {
    font-family: $font-text;
}

.grid-button-label {
    font-family: $font-title;
    font-weight: 700;
    font-size: 1em;
    @media screen and (min-width: $breakpoint-sm) {
        font-size: 1em;
    }
    @media screen and (min-width: $breakpoint-md) {
        font-size: 1.4em;
    }
}

a.judge-external-link{
    text-decoration: underline;
    font-weight: 600;
    color: $color-accent1 !important;
    &:hover{
        color:lighten($color-accent1, 10%)!important;
    }
}
